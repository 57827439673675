import {NavigationItemWithActive} from '../app/shared/interfaces/navigation-item-with-active.interface';

/**
 * Configuration that is consistent across environments
 */
export const STATIC_CONFIG = {
  displayName: 'EDS',
  elements: {

    /**
     * This is removed from the components selector
     * when registering it as an element
     */
    componentPrefix: 'jms-',

    /**
     * This is added as the suffix instead
     * 
     * @example
     * A component with the selector jms-table
     * is registered as an element with the selector
     * jms-e-table
     */
    selectorPrefix: 'jms-e-',
  },
  login: {
    email: true,
    google: true,
    facebook: true
  },
  /**
   * We use this to redirect all unauthenticated users
   */
  loginRoute: ['/login'],
  /**
   * Used for redirecting all authenticated users
   * visiting pages for unauthenticated users
   */
  dashboardRoute: ['/dashboard'],
  navigation: {
    items: [
      {
        label: 'DASHBOARD',
        type: 'link',
        value: '/dashboard',
        authorized: ['admin'],
        icon: 'home'
      },
      {
        label: 'Assignments',
        type: 'expandable',
        icon: 'assignment',
        authorized: ['admin', 'medical', 'nurse'],
        children: [
          {
            label: 'Current Assignments',
            type: 'link',
            value: '/m/assignments',
          },
          {
            label: 'Completed Assignments',
            type: 'link',
            value: '/m/completed-assignments',
          },
          {
            label: 'Assignment Types',
            type: 'link',
            value: '/m/assignment-types'
          },
          {
            label: 'Locations',
            type: 'link',
            value: '/m/locations'
          }
        ]
      } as any,
      {
        label: 'Destinations',
        type: 'link',
        value: '/m/destinations',
        authorized: ['admin'],
        icon: 'location_on'
      },
      {
        label: 'Chief Complaints',
        type: 'link',
        value: '/m/chief-complaints',
        authorized: ['admin'],
        icon: 'insert_drive_file'
      },
      {
        label: 'App Users',
        type: 'link',
        value: '/m/app-users',
        authorized: ['admin'],
        icon: 'person'
      },
      {
        label: 'Reports',
        type: 'link',
        value: '/reports',
        icon: 'bar_chart',
      },
      {
        label: 'USERS',
        type: 'link',
        value: '/m/users',
        authorized: ['admin'],
        icon: 'account_circle'
      },
      {
        label: 'Documentation',
        type: 'expandable',
        icon: 'folder_open',
        authorized: ['admin'],
        children: [
          {
            label: 'SOP',
            type: 'link',
            value: '/m/documentation'
          },
          {
            label: 'Procedures',
            type: 'link',
            value: '/m/procedures'
          }
        ]
      } as any,
      {
        label: 'File Manager',
        type: 'link',
        value: '/storage',
        authorized: ['admin'],
        icon: 'folder'
      },
    ] as NavigationItemWithActive[]
  }
};
